import { Broker, BrokerEngineVersion } from '@amzn/amazonmq-opsconsole-client';
import { KeyValuePairs, Link, Modal, SpaceBetween, Spinner, StatusIndicator, TextContent } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { getBrokerEngineVersions } from '../../api/api';
import { getLatestPublicGAVersion, isEarlierMajorVersion } from '../../version-utils';

type Props = {
    broker: Broker
}

const BrokerEngineField : React.FC<Props> = ({broker}) => {

    const [brokerEngineVersions, setBrokerEngineVersions] = useState<BrokerEngineVersion[] | undefined>(undefined);
    const [versionInfoDialogOpen, setVersionInfoDialogOpen] = useState<boolean>(false);
    const [versionInfoLoading, setVersionInfoLoading] = useState<boolean>(false);
    const [versionInfoError, setVersionInfoError] = useState<string>("");
    const [currentEngineVersion, setCurrentEngineVersion] = useState<BrokerEngineVersion | undefined>(undefined);

    useEffect(() => {
        setVersionInfoLoading(true);
        setVersionInfoError("");
        getBrokerEngineVersions(broker.summary.brokerEngineType).then(response => {
            setBrokerEngineVersions(response.brokerEngineVersions);
            setCurrentEngineVersion(response.brokerEngineVersions.find(bev => bev.version === broker.brokerInfo.engineVersion));
        }).catch(error => {
            console.error(error);
            setVersionInfoError("Could not load broker engine versions.");
        }).finally(() => {
            setVersionInfoLoading(false);
        })
    }, []);

    function renderVersionInfoHelp() {
        if (brokerEngineVersions === undefined) {
            return null;
        }
        const latestVersion = getLatestPublicGAVersion(brokerEngineVersions);

        if (latestVersion === undefined) {
            return null;
        }

        if (isEarlierMajorVersion(broker.brokerInfo.engineVersion, latestVersion)) {
            return (
                <StatusIndicator type='warning'>Not latest major version</StatusIndicator>
            )
        }

        return null;
       
    }

    return (
        <>
            <Modal
                visible={versionInfoDialogOpen}
                onDismiss={() => setVersionInfoDialogOpen(false)}
                header={`Version Info`}
            >
                {versionInfoLoading && <Spinner />}
                {versionInfoError && <StatusIndicator type='error'>{versionInfoError}</StatusIndicator>}
                {!versionInfoLoading && !versionInfoError && currentEngineVersion &&
                    <KeyValuePairs
                        columns={2}
                        items={[
                            {
                                label: 'Version',
                                value: currentEngineVersion.version
                            },
                            {
                                label: 'State',
                                value: currentEngineVersion.state
                            },
                        ]}
                    />
                }
            </Modal>
            <SpaceBetween direction='vertical' size='xs'>
                <SpaceBetween direction='horizontal' size='xxs'>
                    <TextContent>Running: {broker.summary.brokerEngineType} <Link onClick={() => {
                        setVersionInfoDialogOpen(true);
                    }}>{broker.brokerInfo.engineVersion}</Link></TextContent>
                    {renderVersionInfoHelp()}
                </SpaceBetween>
                <TextContent>Pending: {broker.brokerInfo.pendingEngineVersion ? broker.brokerInfo.pendingEngineVersion : 'none'}</TextContent>
            </SpaceBetween>
        </>
    )
}

export default BrokerEngineField;