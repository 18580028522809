import { DockerContainerInfo, EC2Instance } from '@amzn/amazonmq-opsconsole-client';
import { Badge, KeyValuePairs, Link, Modal, SpaceBetween, StatusIndicator, TextContent } from '@amzn/awsui-components-react';
import React, { useState } from 'react';
import CopyToClipboardButton from '../../Components/copyToClipboardButton';

type Props = {
    instance: EC2Instance
}

function isBFS(imageName: string) {
    return imageName.includes(".bfs")
}

function findRabbitContainer(dockerContainers: DockerContainerInfo[]): DockerContainerInfo | undefined {
    return dockerContainers.find(container => container.image.includes("amq-rabbitmq"))
}

function stripEcrPath(dockerImageName: string): string {
    let idx = dockerImageName.indexOf('/');
    if (idx < 0) {
        return dockerImageName;
    }
    return dockerImageName.substring(idx + 1);
}

const RabbitDockerImageField: React.FC<Props> = ({instance}) => {
    let rabbitContainer = findRabbitContainer(instance.dockerContainers)
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    if (!rabbitContainer) {
        return (
            <StatusIndicator type="error">Could not find Rabbit container</StatusIndicator>
        )
    }
    return (
        <>
            <Modal
                visible={dialogOpen}
                onDismiss={() => setDialogOpen(false)}
                header={`Rabbit Docker Image`}
            >
                {rabbitContainer &&
                    <KeyValuePairs
                        columns={3}
                        items={[
                            {
                                label: 'Image Name',
                                value: rabbitContainer.image,
                            },
                            {
                                label: 'State',
                                value: rabbitContainer.state,
                            },
                            {
                                label: 'Status',
                                value: rabbitContainer.status,
                            },
                        ]}
                    />
                }
            </Modal>
            <div className="awsui-util-label" style={{margin: 0}}>
                <div style={{display: 'flex', gap: 4, flexDirection: 'row', alignItems: 'center'}}>
                    Docker Image:
                    <Link onClick={() => {
                        setDialogOpen(true);
                    }}>{stripEcrPath(rabbitContainer.image)}</Link>
                    <CopyToClipboardButton text={rabbitContainer.image}/>
                    {isBFS(rabbitContainer.image) &&
                        <Badge color="blue">BFS</Badge>
                    }
                </div>
            </div>
        </>
    )
}

export default RabbitDockerImageField;